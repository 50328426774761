// resources/js/app.js

import './bootstrap';
import 'bootstrap/js/dist/dropdown';
import '../css/app.css';
import '../css/chat.css';
import '../css/global.css';
import '../css/fonts.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import axios from './utils/axiosConfig';  
import { Inertia } from '@inertiajs/inertia';

// Importiere den CookieBanner
import CookieBanner from './Components/CookieBanner.vue';
import PWAInstallCard from './Components/PWAInstallCard.vue'; // Importiere die PWAInstallCard

const appName = 'My StoryPro AI';

// Setze einen globalen Listener für beforeinstallprompt
window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  window.deferredPrompt = e; // Speichere das Ereignis global
  // Optional: Dispatch ein Event oder setze einen Zustand
  //console.log('beforeinstallprompt global captured');
});

Inertia.on('success', (event) => {
    const csrfToken = event.detail.page.props.csrfToken;
    if (csrfToken) {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    }
});

document.addEventListener('DOMContentLoaded', () => {
    createInertiaApp({
        title: (title) => {
            const pageTitles = {
                'Auth/Login': 'Login',
                'Auth/Register': 'Register',
                'Chats/Index': 'Chats',
            };
        
            const pageName = title || '';
        
            const pageTitle = pageTitles[pageName] || title;
        
            if (pageTitle && pageTitle !== appName) {
                return `${appName} | ${pageTitle}`;
            } else {
                return appName;
            }
        },
        resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
        setup({ el, App, props, plugin }) {
            const vueApp = createApp({ 
                render: () => h('div', [
                    h(App, props),
                    h(CookieBanner), // Füge den CookieBanner hier hinzu
                    h(PWAInstallCard) // Füge die PWAInstallCard hier hinzu
                ]) 
            })
            .use(plugin)
            .use(ZiggyVue);

            // CSRF-Token aus den Props holen
            const csrfToken = props.initialPage.props.csrfToken;

            if (csrfToken) {
                axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
            }

            vueApp.config.globalProperties.$axios = axios;

            vueApp.mount(el);
        },
        progress: false,
    });

    // ENTFERNE/KOMMENTIERE DIESEN BLOCK AUS
    /*
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/sw.js').then(registration => {
                //console.log('ServiceWorker registration successful with scope: ', registration.scope);
            }, err => {
                console.log('ServiceWorker registration failed: ', err);
            });
        });
    }
    */

    if ('serviceWorker' in navigator) {
       // Erst versuchen zu unregistrieren
       navigator.serviceWorker.getRegistrations().then(function(registrations) {
           for(let registration of registrations) {
               registration.unregister();
           }
       });

       // Dann prüfen ob noch was da ist
       setTimeout(() => {
           navigator.serviceWorker.getRegistrations().then(function(registrations) {
               if(registrations.length > 0) {
                   const alert = `
                       <div class="alert alert-warning alert-dismissible fade show mt-3 mx-3" role="alert" 
                            style="border-radius: 10px; background-color: #fef9c3; border: none;">
                           <div class="d-flex flex-column">
                               <p class="mb-2">Hey there! We've made some improvements to the app! 🚀</p>
                               
                               <p class="mb-2">To get the best experience, please refresh your browser using CTRL+F5 (Windows) or CMD+R (Mac).</p>
                               
                               <p class="mb-2">If you're still seeing outdated content after refreshing, drop us a message and we'll help you out!</p>
                               
                               <p class="mb-0">Thank you for using StoryPro AI! ✨</p>
                           </div>
                           <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                       </div>
                   `;
                   document.body.insertAdjacentHTML('afterbegin', alert);
               }
           });
       }, 1000);
    }
});
